import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  // connect() {
  //   console.log("hello, Im allCheck_controller.");
  // }

  // formを編集可能な状態にする
  allCheckToggle(e) {
    if (e.target.checked) {
      document.querySelectorAll(".js-checkbox").forEach((e) => {
        e.checked = true;
      });
    } else {
      document.querySelectorAll(".js-checkbox").forEach((e) => {
        e.checked = false;
      });
    }
  }
}
