import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"];

  // connect() {
  //   console.log("hello, Im category_controller.");
  // }

  // formを編集可能な状態にする
  startEdit(e) {
    // フォームのトグル処理
    let textField = e.target.parentNode.parentNode.querySelector(".js-textField");
    textField.disabled = !textField.disabled;
    textField.classList.toggle("disabled-form");
    let submitButton = e.target.parentNode.parentNode.querySelector(".js-formButton");
    submitButton.classList.toggle("disabled-form__submit");
    // e.target.blur();
    document.activeElement.blur();
  }
}
