import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["corp", "output"];

  // connect() {
  //   console.log("hello, Im form_controller.");
  // }

  changePullDown() {
    const path = "/admin/corporations/" + this.corpTarget.value + "/offices/narrowing.json";
    callApi(path, this.outputTarget, false);
  }

  changeSearchPullDown() {
    const path = "/admin/corporations/" + this.corpTarget.value + "/offices/narrowing.json";
    callApi(path, this.outputTarget, true);
  }
}

async function callApi(path, e, blankRequired) {
  try {
    const res = await fetch(path);
    const offices = await res.json();
    while (e.firstChild) {
      e.removeChild(e.firstChild);
    }

    if (blankRequired) {
      e.insertAdjacentHTML("afterbegin", `<option value=''>全ての事業所</option>`);
    }
    // 事業所の存在チェック
    if (offices.length) {
      offices.forEach(function (value) {
        e.insertAdjacentHTML("afterbegin", `<option value='${value.id}'>${value.name}</option>`);
      });
    } else {
      e.insertAdjacentHTML("afterbegin", `<option value>事業所が存在しません</option>`);
    }
  } catch (err) {
    console.error(err);
  }
}
