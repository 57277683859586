import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["corp", "output"];
  static values = { enable: Boolean };

  connect() {
    // console.log("hello, Im csvModal_controller.");
  }

  changePullDown() {
    const path = "/admin/corporations/" + this.corpTarget.value + "/offices/narrowing.json";
    callApi(path, this.outputTarget, this.enableValue);
  }
}

async function callApi(path, e, hasBlank) {
  try {
    const res = await fetch(path);
    const offices = await res.json();
    while (e.firstChild) {
      e.removeChild(e.firstChild);
    }

    if (hasBlank) {
      e.insertAdjacentHTML("afterbegin", `<option value=''>全ての事業所</option>`);
    }
    offices.forEach(function (value) {
      e.insertAdjacentHTML("afterbegin", `<option value='${value.id}'>${value.name}</option>`);
    });
  } catch (err) {
    console.error(err);
  }
}
