import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["corp", "division", "wakasa", "toa", "other", "job", "position"];

  connect() {
    formChange(this.divisionTarget.value, this.toaTarget, this.wakasaTarget);
  }

  changeDivision() {
    formChange(this.divisionTarget.value, this.toaTarget, this.wakasaTarget);
  }

  changeCorporation() {
    // 職種、役職のプルダウンの初期化処理
    pullDownInit(this.jobTarget);
    pullDownInit(this.positionTarget);

    // json呼び出し、法人に紐づくメニューの表示処理
    const url = "/admin/corporations/" + this.corpTarget.value + "/category.json";
    callCategory(url, this.jobTarget, this.positionTarget);
  }
}

// フォーム自体の変更処理
function formChange(value, toa, wakasa) {
  switch (value) {
    case "toa":
      toa.classList.remove("d-none");
      wakasa.classList.add("d-none");
      break;
    case "wakasa":
      toa.classList.add("d-none");
      wakasa.classList.remove("d-none");
      break;
    case "other":
      toa.classList.add("d-none");
      wakasa.classList.remove("d-none");
      break;
    default:
      toa.classList.add("d-none");
      wakasa.classList.add("d-none");
      break;
  }
}

// jsonの取得処理
async function callCategory(url, jobElement, positionElement) {
  try {
    const res = await fetch(url);
    const categories = await res.json();

    if (categories.job.length > 0) {
      for (let i = 0; i < categories.job.length; i++) {
        jobElement.insertAdjacentHTML("afterbegin", `<option value='${categories.job[i].id}'>${categories.job[i].title}</option>`);
      }
    } else {
      jobElement.insertAdjacentHTML("afterbegin", `<option value=''>未設定</option>`);
    }

    if (categories.job.length > 0) {
      for (let i = 0; i < categories.position.length; i++) {
        positionElement.insertAdjacentHTML("afterbegin", `<option value='${categories.position[i].id}'>${categories.position[i].title}</option>`);
      }
    } else {
      positionElement.insertAdjacentHTML("afterbegin", `<option value=''>未設定</option>`);
    }
  } catch (err) {
    console.error(err);
  }
}

// 選択肢のクリア関数
function pullDownInit(e) {
  while (e.firstChild) {
    e.removeChild(e.firstChild);
  }
}
